<template>
  <div class="user-agreement" :class="{ mobile: isMobile }">
    <StCheckbox
      v-model="isAgreementChecked"
      :accent-color="accentColor"
      data-t="checkbox"
    >
      <div class="text">
        <i18n-t keypath="registration.acceptRules">
          <template #rules>
            <a :href="termsUrl" target="_blank" @click.stop>
              {{ t('registration.rules') }}
            </a>
          </template>
          <template #policy>
            <a :href="privacyPolicyUrl" target="_blank" @click.stop>
              {{ t('registration.policy') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </StCheckbox>
  </div>
</template>

<script setup lang="ts">
interface Props {
  modelValue: boolean
  isMobile?: boolean
  accentColor?: string
}

const props = withDefaults(defineProps<Props>(), {
  accentColor: '#FE5E01',
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const { t } = useI18n()

const isAgreementChecked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

const termsUrl = usePublicFileUrl('publicFileTerms')
const privacyPolicyUrl = usePublicFileUrl('publicFilePrivacyPolicy')
</script>

<style scoped>
.user-agreement {
  display: flex;

  .text {
    display: block;

    margin-left: var(--spacing-050);
    padding-right: var(--spacing-500);

    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-600);

    a {
      color: v-bind(accentColor);
      text-decoration: none;
      white-space: nowrap;
      opacity: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.mobile {
    .text {
      padding: 0;
      font: var(--mobile-text-regular);
    }
  }
}
</style>
