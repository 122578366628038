<template>
  <div
    class="second-step"
    data-t="second-step-form"
    :class="{ mobile: props.isMobile, landing: props.isLanding }"
  >
    <div class="content">
      <h2 class="title">
        {{ t('registration.secondStepTitle') }}
      </h2>
      <p class="instructions">
        <I18nT keypath="registration.confirmEmailInstructions">
          <template #email>
            <span class="email">
              {{ props.firstStepData.email }}
            </span>
          </template>
        </I18nT>
      </p>
      <St2FA
        ref="st2fa"
        :is-code-valid="isCodeValid"
        :error="error"
        is-wide
        :platform="props.isMobile ? 'mobile' : 'desktop'"
        :is-no-email-access-visible="false"
        @input-filled="inputFilled"
        @resend-code-clicked="resendCode"
      />
    </div>

    <div v-if="!props.isMobile" class="go-back-button">
      <StButton
        :label="t('authorization.secondStepGoBack')"
        size="l"
        type="text-only"
        icon="arrow-left"
        is-left-icon
        @click="emit('back')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import St2FA from '@st/core/components/St2Fa/St2Fa.vue'
import { useUserStore } from '../../../stores/useUserStore'

const { handleLogin } = useUserStore()
const { t, locale } = useI18n()
const vibrate = useVibration()

const st2fa = useTemplateRef<InstanceType<typeof St2FA>>('st2fa')

const props = defineProps<{
  firstStepData: {
    email: string
    password: string
    promocodeName?: string
    referralCode?: string
    isCodeSent?: boolean
  }
  isMobile?: boolean
  isLanding?: boolean
}>()

onActivated(() => {
  if (props.firstStepData.isCodeSent) {
    st2fa.value?.restartTimer()
  }
})

const emit = defineEmits<{
  back: []
  success: []
}>()

const { getToken, recaptchaQueryParam } = useRecaptcha()
const { execute: executeResendCode } = useStFetch('/registration/code/send', {
  method: 'post',
  body: computed(() => {
    const { email, password, referralCode, promocodeName } = props.firstStepData
    const language = locale.value as any

    return referralCode
      ? {
          email,
          password,
          referralCode,
          language,
        }
      : {
          email,
          password,
          language,
          promocodeName,
        }
  }),
  immediate: false,
  watch: false,
  query: recaptchaQueryParam,
})

async function resendCode() {
  await getToken('/registration/code/send')
  executeResendCode()
}

const code = ref('')

const requestBody = computed(() => {
  const { email, password, promocodeName, referralCode } = props.firstStepData

  return referralCode
    ? {
        email,
        password,
        referralCode,
        language: locale.value as any,
        code: code.value,
      }
    : {
        email,
        password,
        language: locale.value as any,
        promocodeName,
        code: code.value,
      }
})

const isCodeValid = ref(false)

const {
  execute,
  error,
  data: userData,
} = useStFetch('/registration', {
  method: 'post',
  body: requestBody,
  immediate: false,
  watch: false,
})

async function sendRequest() {
  await execute()
  if (!userData.value) {
    vibrate()
    return
  }
  /** @TODO [St2FA] схлопнуть в одну реализацию */
  isCodeValid.value = true
  await delay(1500)
  handleLogin(userData.value)
  emit('success')
}

function inputFilled(value: string) {
  code.value = value

  sendRequest()
}
</script>

<style scoped>
.title {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-xl-semibold);
}

.content {
  flex-grow: 1;
}

.instructions {
  margin: var(--spacing-100) 0 var(--spacing-300);
  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);
}

.email {
  color: var(--text-primary);
}

.second-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
}

.go-back-button {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.mobile {
  &.second-step {
    gap: var(--spacing-100);

    &.landing {
      padding-bottom: var(--spacing-150);
    }
  }

  .instructions {
    margin: 0 0 var(--spacing-100);
    font: var(--mobile-text-content-regular);
  }

  .title {
    margin: var(--spacing-200) 0 var(--spacing-050);
    font: var(--mobile-title-2-semibold);
  }
}
</style>
