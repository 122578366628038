<template>
  <form
    class="form"
    :class="{ mobile: isMobile }"
    data-t="first-step-form"
    @submit.prevent="handleSubmit"
  >
    <h2 v-if="!props.isMobile" class="title">
      {{ t('registration.firstStepTitle') }}
    </h2>
    <div class="auth-wrapper">
      <ProvidersAuth
        :is-email-enabled="isEmailEnabled"
        :is-mobile="isMobile"
        :is-google-enabled="isGoogleEnabled"
        :is-disabled-providers="isDisabledProviders"
        :converted-referral-code="convertedReferralCode"
        :converted-promocode-name="convertedReferralCode"
        @finish-tg="emit('finishTg')"
      />
      <div v-if="isEmailEnabled" class="email-auth">
        <div class="button-separator">
          <span class="line-separator" />
          <span>{{ t('registration.or') }}</span>
          <span class="line-separator" />
        </div>
        <div class="email-inputs">
          <StInput
            v-bind="email.componentBindings"
            :label="t('registration.emailLabel')"
            :placeholder="t('registration.emailPlaceholder')"
            inputmode="email"
            :size="props.isMobile ? 'm' : 'l'"
            type="email"
            data-t="email"
            class="email-input"
          />
          <StInputPassword
            v-bind="password.componentBindings"
            :label="t('registration.passwordLabel')"
            :placeholder="t('registration.passwordPlaceholder')"
            :rules="passwordValidators"
            type="password"
            :error="!!errorMessage || !!password.error"
            :error-message="errorMessage || password.error"
            :size="props.isMobile ? 'm' : 'l'"
            :rules-position="props.isMobile ? 'top' : 'bottom'"
            data-t="password"
            class="password-input"
          />
        </div>
      </div>
      <EmailRegistrationDisabled v-else />
    </div>
    <div class="actions-wrapper" :class="{ mobile: props.isMobile }">
      <UserAgreement v-model="isAgreementChecked" :is-mobile="props.isMobile" />
      <div class="actions-buttons">
        <StButton
          v-if="isEmailEnabled"
          submit
          class="submit-button"
          :label="t('registration.firstStepButton')"
          :size="props.isMobile ? 'l' : 'xl'"
          data-t="submit-button"
          :loading="requestStatus === 'pending'"
          :disabled="isDisabledSubmit"
        />
      </div>
      <div class="login" :class="{ single: !props.isGoogleEnabled }">
        {{ t('registration.accountExist') }}
        <StButton
          type="text-only"
          :label="t('registration.login')"
          size="l"
          replace
          :to="{ query: { modal: 'login' } }"
        />
      </div>
      <PromocodeInput
        v-model="promocodeInputValue"
        :is-mobile="props.isMobile"
        :promocode-error-message="promocodeErrorMessage"
        class="promocode"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { required, useForm, validEmail } from '@st/validate'
import { useTimeoutTimer } from '@st/use/composables'
import { usePasswordValidator } from '../../../composables/usePasswordValidator'
import { useCheckPromocode } from '../../../composables/useCheckPromocode'
import PromocodeInput from './PromocodeInput.vue'
import UserAgreement from './UserAgreement.vue'
import EmailRegistrationDisabled from './EmailRegistrationDisabled.vue'
import ProvidersAuth from './ProvidersAuth.vue'

const props = defineProps<{
  isMobile: boolean
  isGoogleEnabled: boolean
  isEmailEnabled: boolean
  isLanding: boolean
}>()
const emit = defineEmits<{
  (
    e: 'success',
    payload: { email: string; password: string; isCodeSent: boolean },
  ): void
  (e: 'finishTg'): void
}>()

const { t, locale } = useI18n()

const { passwordValidators } = usePasswordValidator()

const {
  fields: { email, password },
  values,
  isValid,
} = useForm({
  fieldsSchema: {
    email: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        {
          rule: validEmail,
          errorMessage: t('registration.errorMessages.emailShouldBeValid'),
        },
      ],
    },
    password: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        ...passwordValidators.value,
      ],
    },
  },
})

const { getToken, isLoadingRecaptcha, recaptchaQueryParam } = useRecaptcha()

const promocodeInputValue = ref('')
const { convertedReferralCode, convertedPromocodeName, promocodeErrorMessage } =
  useCheckPromocode(promocodeInputValue)

const requestCodeSendBody = computed(() =>
  convertedReferralCode.value
    ? {
        ...values.value,
        language: locale.value as any,
        referralCode: convertedReferralCode.value,
      }
    : {
        ...values.value,
        language: locale.value as any,
        promocodeName: convertedPromocodeName.value,
      },
)

const {
  execute,
  error,
  status: requestStatus,
} = useStFetch('/registration/code/send', {
  method: 'post',
  body: requestCodeSendBody,
  immediate: false,
  watch: false,
  query: recaptchaQueryParam,
})

const isAgreementChecked = ref(true)

const { startTimer, isFinished } = useTimeoutTimer()

const errorCodes = computed<Record<string, string>>(() => ({
  RECAPTCHA_IS_REQUIRED: t('registration.errorMessages.recaptchaRequired'),
  RECAPTCHA_IS_INVALID: t('registration.errorMessages.recaptchaInvalid'),
  VALIDATION_ERROR: t('registration.errorMessages.validationError'),
  USER_PASSWORD_REQUIREMENT_VIOLATION: t(
    'registration.errorMessages.userPasswordRequirementsViolation',
  ),
  RECAPTCHA_REQUEST_ERROR: t(
    'registration.errorMessages.recaptchaRequestError',
  ),
  AUTH_FORBIDDEN: 'authorization.errorMessages.selfExclusionError',
}))

watch(isFinished, () => {
  password.reset()
})

const isDisabledSubmit = computed(
  () =>
    !(
      isValid.value &&
      isAgreementChecked.value &&
      !promocodeErrorMessage.value
    ) || isLoadingRecaptcha.value,
)

const isDisabledProviders = computed(() => !isAgreementChecked.value)

watch(
  () => error.value,
  (err) => {
    if (!err) return
    const errorBody = err.data
    if (!errorBody) return

    const { error: errorCode } = errorBody

    if (errorCode === 'CONFIRMATION_CODE_RESEND_TIMEOUT') {
      startTimer(errorBody.data.ttl)
    }
  },
)

const errorMessage = computed(() => {
  const errorBody = error.value?.data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody

  return errorCodes.value[errorCode] || undefined
})

async function handleSubmit() {
  await getToken('/registration/code/send')
  await execute()
  if (
    !error.value ||
    error.value?.data?.error === 'CONFIRMATION_CODE_RESEND_TIMEOUT'
  ) {
    emit('success', {
      ...requestCodeSendBody.value,
      isCodeSent: !error.value,
    })
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.auth-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.error-message {
  font: var(--desktop-text-sm-medium);
  color: var(--system-error);
}

.promocode {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 48px;
  margin-top: auto;
}

.login {
  margin-top: var(--spacing-050);
  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);
  text-align: center;

  &.single {
    margin-top: auto;
  }
}

.actions-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-250);
}

.actions-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
}

.button-separator {
  display: flex;
  gap: var(--spacing-250);
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
  text-transform: lowercase;
}

.email-auth {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
}

.email-inputs {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
}

.line-separator {
  flex-grow: 1;
  height: 0.5px;
  background: var(--border-primary);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-250);

  &.mobile {
    gap: var(--spacing-200);

    .button-separator {
      font: var(--mobile-caption-1-regular);
    }

    .email-auth {
      gap: var(--spacing-075);
    }

    .email-inputs {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-200);
    }

    .actions-wrapper {
      gap: var(--spacing-200);
    }

    .login {
      margin-top: 0;
      font: var(--mobile-text-content-regular);
    }

    .auth-wrapper {
      gap: var(--spacing-150);
    }
  }
}
</style>
